const en = {
  heading: {
    title: "Get your NFT here",
    description: "Purchase them now on lostin.com",
    buttonText: "Go to store",
  },
  introText:
    "We are introducing the LOST iN Turismo Community. It is a space where LOST iN readers can interact with each other and the brand. Participation is granted through the purchase of a unique token (NFT) depicting a remixed version of our iconic cover. This token then grants you special access as well as participation in our upcoming editions as we open up to our community. We hope you are as excited as us in this new chapter for our brand and welcome you to join us on this trip.",
  playlists: {
    title: "Playlists",
    description: "Exclusive playlists - enabled by your NFT Collection",
  },
  nftCollections: {
    title: "Your collection",
    description: "",
    showAllText: "View Profile",
  },
  marketplace: {
    title: "Marketplace",
    description: "",
    showAllText: "Go to Marketplace",
    forSale: "for sale",
    priceFrom: "From",
  },
  merchandise: {
    title: "Exclusive Merchandise",
    description: "Exclusive merchandise - enabled by your NFT Collection",
  },
  archive: {
    title: "Archive",
    description: "Exclusive archives - enabled by your NFT Collection",
  },
  discord: {
    title: "Discord",
    description: "Exclusive streams - enabled by your NFT Collection",
  },
  videos: {
    title: "Videos",
    description: "Exclusive videos - enabled by your NFT Collection",
  },
  locked: "Locked",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
