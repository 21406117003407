import Button from "components/Widgets/Button";
import ButtonHref from "components/Widgets/ButtonHref";
import Modal from "components/Widgets/Modal";
import { Blockchain, Config, getLocalizedStrings, Project } from "config";
import BloctoPng from "config/global/img/blocto.png";
import DapperPng from "config/global/img/dapper.png";
import MetaMaskPng from "config/global/img/metamask.png";
import { AppContext } from "context/AppContext";
import { useLanguage } from "lib/contexts/LanguageContext";
import { ethAPI } from "lib/store/api/eth";
import { setEthConnectingWalletProvider } from "lib/store/api/eth/web3";
import { flowAPI } from "lib/store/api/flow";
import { useContext } from "react";
import custom_styles from "./custom.module.scss";

export function SelectWallet() {
  const appContext = useContext(AppContext) as AppContext;
  const strings = getLocalizedStrings("Modals", "SelectWallet", useLanguage());
  const availableWallets = Project.WALLET_TYPE as WalletType[];
  const [onBlockchainLogin] = ethAPI.useLoginMutation();
  const [onBlockchainLogout] = ethAPI.useLogoutMutation();
  const [login] = flowAPI.useLoginMutation();
  const [logout] = flowAPI.useLogoutMutation();

  const getSrc = (wallet: WalletType) => {
    switch (wallet) {
      case "Blocto":
        return BloctoPng;
      case "MetaMask":
        return MetaMaskPng;
      case "Dapper":
        return DapperPng;
      default:
        return BloctoPng;
    }
  };

  const onLogin = async (wallet: WalletType) => {
    appContext.selectWallet.display.setIsVisible(false);
    switch (Blockchain.BLOCKCHAIN_NAME) {
      case "polygon":
        return (
          setEthConnectingWalletProvider(wallet as EthWalletType),
          onBlockchainLogout({ isDisconnecting: false }),
          onBlockchainLogin()
        );
      case "flow":
        return await logout(), await login();
    }
  };

  return (
    <Modal
      clickOutsideToClose={false}
      isVisible={appContext.selectWallet.display.isVisible}
      onClose={() => {
        appContext.selectWallet.display.setIsVisible(false);
        appContext.selectWallet.callbacks.onClose();
      }}
    >
      <h2>{strings.title}</h2>

      <div className={`${custom_styles.buttonsWrapper} ${custom_styles.top}`}>
        {availableWallets.map((wallet, i) => (
          <div key={i} className={custom_styles.iconWrapper}>
            <Button
              type="square"
              className={`pa-2 ${custom_styles.button}`}
              style={{ background: "white" }}
              onClick={() => {
                onLogin?.(wallet);
              }}
            >
              <img src={getSrc(wallet)} style={{ width: "35px" }} />
            </Button>
            <span className={custom_styles.walletType}>{wallet}</span>
          </div>
        ))}
      </div>

      <hr />
      <div className={custom_styles.bottom}>
        <h3>{strings.title2}</h3>
        <p>{strings.description}</p>
        <div className={custom_styles.buttonsWrapper}>
          <Button
            type="secondary"
            className={custom_styles.button}
            onClick={() => onLogin(availableWallets[0])}
          >
            {strings.getWallet}
          </Button>

          <ButtonHref
            type="secondary"
            className={custom_styles.button}
            href={Config.Client.WALLET_FAQ_LINK}
            target="_blank"
          >
            {strings.learnMore}
          </ButtonHref>
        </div>
        <p>{strings.legal}</p>
      </div>
    </Modal>
  );
}
