const en = {
  greetingTitle: "Wallet",
  greetingSubtitle: "All your digital collectibles in one place.",
  greetingBtn: "Open your wallet",
  emailTitle: "Please enter your email to get access to your wallet.",
  codeTitle: "Please enter the six-digit code that was sent to your email.",
  codeResend: "Resend code",
  title: "Log in to access your NFT",
  emailLabel: "Your email",
  emailPlaceholder: "example@domain.com",
  loginCodeSent:
    "We just sent you a temporary login code. Please check your inbox.",
  loginCodeLabel: "Login code",
  loginButton: "LOGIN",
  viewMarketplace: "View Marketplace",
  continue: "Continue",
  invalidLogin: "Invalid login",
  errorModal: {
    title: "Error",
    subtitle: "The login link is not valid:",
    btnTxt: "Close",
  },
  errorPage: {
    title: "Error",
    btnTxt: "Go to login page",
    invalidTokenFormat: "The magic link is not valid",
    networkValidationError: "An error occurred while validating the token",
  },
  agreeToTOS: "by Clicking continue you agree to these terms of services:",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
