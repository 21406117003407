import { withPrefix } from "@blocto/fcl";
import ActionModals from "components/AppComponent/ActionModals";
import { Blockchain, Config, getLocalizedStrings, Project } from "config";
import {
  MARKETPLACE_ROUTE,
  NFT_LIST_ROUTE,
  SALES_HISTORY_ROUTE,
} from "config/routes";
import { useLanguage } from "lib/contexts/LanguageContext";
import { flowAPI } from "lib/store/api/flow";
import {
  useClaim,
  useCurrentWallet,
  useListForSale,
  usePurchase,
  useTransfer,
  useWalletSetupStatus,
  useWithdraw,
} from "lib/store/api/flow/hooks";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClaimStepList, TokenInWalletSection } from "../components";
import { ModalVisible } from "../NftShowPage";
import { NftShowUI } from "../ui/NftShowUI";
import {
  canClaim,
  canPurchase,
  canSell,
  canTransfer,
  canWithdraw,
} from "../util";
import { ChangeWalletButtonsFlowConnector } from "./ChangeWalletButtonsFlowConnector";
import { ClaimButtonFlowConnector } from "./ClaimButtonFlowConnector";
import { LoginButtonFlowConnector } from "./LoginButtonFlowConnector";
import { PlaceForSaleButtonFlowConnector } from "./PlaceForSaleButtonFlowConnector";
import { PriceFlowConnector } from "./PriceFlowConnector";
import { PurchaseButtonFlowConnector } from "./PurchaseButtonFlowConnector";
import { TransferButtonFlowConnector } from "./TransferButtonFlowConnector";
import { WithdrawButtonFlowConnector } from "./WithdrawButtonFlowConnector";

export function NftShowFlowConnector({
  itemFID,
  contractAddress,
  contractName,
}: {
  itemFID: string;
  contractAddress: string;
  contractName: string;
}) {
  const navigate = useNavigate();
  const user = useAppSelector(selectAppUser);
  const userId = user ? String(user.id) : undefined;
  const [modalVisible, setModalVisible] = useState<ModalVisible | null>(null);
  const hasMarketplace = Config.MarketPlace.hasMarketplace;

  const strings = getLocalizedStrings("Pages", "Details", useLanguage());

  const { data: smartContractsWithSetup } = useWalletSetupStatus();

  const {
    data: nft,
    isLoading: isNftLoading,
    isError,
    refetch,
  } = flowAPI.useGetFullNftQuery({
    itemFID,
    contractAddress,
    contractName,
  });

  const {
    data: latestTransaction,
    isLoading: isLatestTransactionLoading,
    isError: isLatestTransactionError,
  } = flowAPI.useGetLatestTransactionQuery({
    eventType: contractName,
    itemFID,
  });

  const restrictionBasedOnEditionName =
    nft?.edition?.name === Config.MarketPlace.editionRestrictionName;

  // This is far from a good solution..
  // The problem is nft-state depends on if the user connected or not..
  useEffect(() => {
    if (user?.id) {
      refetch();
    }
  }, [user?.id]);

  const [
    claim,
    {
      isSuccess: isClaimSuccess,
      isError: isClaimError,
      isLoading: isClaimLoading,
    },
  ] = useClaim({
    itemFID,
    contractName,
    contractAddress,
  });

  const [
    withdraw,
    {
      isLoading: isWithdrawLoading,
      isSuccess: isWithdrawSuccess,
      isError: isWithdrawError,
      reset: withdrawReset,
    },
  ] = useWithdraw({
    itemFID,
    contractName,
    contractAddress,
  });

  const [
    listForSale,
    {
      isLoading: isListForSaleLoading,
      isSuccess: isListForSaleSuccess,
      errorMessage: listForSaleErrorMessage,
      reset: listForSaleReset,
    },
  ] = useListForSale({ itemFID, contractName, contractAddress });

  const { data: lowestPrice, isLoading: isPriceLoading } =
    flowAPI.useGetLowestPriceForEditionQuery(
      { nft },
      { skip: !nft || Config.Client.HIDE_EDITION_LOWEST_PRICE }
    );
  const { data: fees } = flowAPI.useGetFeesQuery(
    {
      itemFID,
      contractName,
      contractAddress,
      ownerAddress: nft?.currentAddress as string,
    },
    { skip: !nft?.currentAddress }
  );

  const [
    transfer,
    {
      isLoading: isTransferLoading,
      isSuccess: isTransferSuccess,
      errorMessage: transferErrorMessage,
      reset: transferReset,
    },
  ] = useTransfer({ itemFID, contractName, contractAddress });

  const onTransferClick = () => {
    if (recipient?.address && contractAddress && contractName) {
      transfer(recipient?.address, contractAddress, contractName);
    }
  };

  const [
    onGetRecipient,
    {
      data: recipient,
      isLoading: isLoadingRecipient,
      isUninitialized: isRecipientUninitialized,
      reset: recipientReset,
    },
  ] = flowAPI.useGetRecipientMutation();

  const [purchase, { isLoading: isPurchaseLoading, isError: isPurchaseError }] =
    usePurchase({ itemFID, contractName, contractAddress });

  const queryResults = {
    claim: {
      isClaimSuccess,
      isClaimError,
      isClaimLoading,
    },
    withdraw: {
      isWithdrawLoading,
      isWithdrawSuccess: isWithdrawSuccess && !nft?.state?.isForSale,
      isWithdrawError,
      withdrawReset,
    },
    listForSale: {
      isListForSaleLoading,
      isListForSaleSuccess: isListForSaleSuccess && !!nft?.state?.isForSale,
      listForSaleErrorMessage,
      listForSaleReset,
      lowestPrice,
      fees,
      listForSale,
    },
    transfer: {
      recipientStep: {
        recipient,
        isLoadingRecipient,
        isRecipientUninitialized,
        recipientReset,
        onGetRecipient,
      },
      transferStep: {
        transfer: onTransferClick,
        isTransferLoading,
        isTransferSuccess,
        transferErrorMessage,
        transferReset,
      },
    },
    purchase: {
      isPurchaseLoading,
      isPurchaseError,
    },
  };

  const onSalesHistoryClick =
    Config.MarketPlace.hasMarketplace && nft != null
      ? () =>
          navigate(
            `${SALES_HISTORY_ROUTE}${NFT_LIST_ROUTE}/${withPrefix(
              nft.smartContractAddress
            )}/${nft?.smartContractName}/${itemFID}`
          )
      : undefined;

  const onMerchantClick =
    Config.MarketPlace.hasMarketplace && nft != null
      ? () => navigate(`${MARKETPLACE_ROUTE}`)
      : undefined;

  const shareLink = `${window.location.protocol}//${window.location.host}/public${NFT_LIST_ROUTE}/${contractAddress}/${contractName}/${itemFID}`;

  const [displayUnclaimableMessage, setDisplayUnclaimableMessage] = useState<
    string | null
  >(null);

  const wallet = useCurrentWallet();
  const { data: wallets } = flowAPI.useGetWalletListQuery({ userId });
  const isUsersNft = Boolean(
    wallets?.find((wallet) => wallet.address === nft?.currentAddress)
  );

  const isCurrentWalletNftOwner = Boolean(
    wallet?.address === nft?.currentAddress
  );

  const isPriceInfoLoading = isNftLoading || isPriceLoading;
  const displayPrice =
    Boolean(nft?.saleInfo?.price || lowestPrice) && !isPriceInfoLoading;

  const displayChangeWalletButton = Boolean(
    user &&
      wallet &&
      isUsersNft &&
      !isCurrentWalletNftOwner &&
      nft?.state.isClaimed
  );
  const displayConnectWallet = Boolean(user && !wallet?.address);
  const isNftClaimed = !!nft?.state?.isClaimed;
  const renderClaimStepList = !!nft?.state?.isOwner && !isNftClaimed;

  const associatedSmartContract = smartContractsWithSetup?.find(
    (walletContract) =>
      walletContract.name === nft?.smartContractName &&
      withPrefix(walletContract.address) ===
        withPrefix(nft?.smartContractAddress)
  );
  const stepConnectWallet = !!wallet;
  const stepInitializeWallet = associatedSmartContract?.isSetup;

  const displayChangeWallet =
    !stepConnectWallet &&
    !isNftClaimed &&
    Project.WALLET_TYPE &&
    (displayChangeWalletButton || displayConnectWallet);

  const contractAddressLink = `${Project.FLOW_CONTRACT_ADDRESS_LINK}${nft?.smartContractAddress}.${Project.SMART_CONTRACTS[0].name}`;
  const flowTransactionLink = latestTransaction
    ? `${Project.FLOW_TRANSACTION_ADDRESS_LINK}${latestTransaction?.transactionId}`
    : null;

  return (
    <>
      <NftShowUI
        nft={nft}
        isLoading={isNftLoading}
        isError={isError}
        onSalesHistoryClick={onSalesHistoryClick}
        onMerchantClick={onMerchantClick}
        shareLink={shareLink}
        displayUnclaimableMessage={displayUnclaimableMessage}
        setDisplayUnclaimableMessage={setDisplayUnclaimableMessage}
        wallet={wallet}
        isCurrentWalletNftOwner={isCurrentWalletNftOwner}
        wallets={wallets}
        restrictionBasedOnEditionName={restrictionBasedOnEditionName}
        currentOwnerAddress={nft?.currentAddress}
      >
        {!renderClaimStepList && (
          <>
            {displayPrice && (
              <>
                <PriceFlowConnector
                  lowestMarketPrice={lowestPrice}
                  currentListedPrice={nft?.saleInfo?.price}
                />
              </>
            )}
            {Project.WALLET_TYPE &&
              (displayChangeWalletButton || displayConnectWallet) && (
                <ChangeWalletButtonsFlowConnector
                  currentOwnerAddress={nft?.currentAddress}
                  currentWalletAddress={wallet?.address}
                  displayChangeWalletButton={displayChangeWalletButton}
                  displayConnectWallet={displayConnectWallet}
                />
              )}
            {canClaim(nft) && Project.WALLET_TYPE && (
              <ClaimButtonFlowConnector
                itemFID={itemFID}
                contractAddress={contractAddress}
                contractName={contractName}
                setModalVisible={setModalVisible}
                claim={(walletAddress: string, nftID: number) =>
                  claim(walletAddress, nftID)
                }
                setDisplayUnclaimableMessage={setDisplayUnclaimableMessage}
              />
            )}
            {canWithdraw(nft) && (
              <WithdrawButtonFlowConnector
                itemFID={itemFID}
                contractAddress={contractAddress}
                contractName={contractName}
                setModalVisible={setModalVisible}
                withdraw={withdraw}
              />
            )}
            {canSell(nft) && (
              <PlaceForSaleButtonFlowConnector
                itemFID={itemFID}
                contractAddress={contractAddress}
                contractName={contractName}
                setModalVisible={setModalVisible}
              />
            )}
            {canTransfer(nft) && (
              <TransferButtonFlowConnector
                itemFID={itemFID}
                contractAddress={contractAddress}
                contractName={contractName}
                setModalVisible={setModalVisible}
              />
            )}
            {canPurchase(nft) && (
              <PurchaseButtonFlowConnector
                itemFID={itemFID}
                contractAddress={contractAddress}
                contractName={contractName}
                setModalVisible={setModalVisible}
                purchase={purchase}
              />
            )}
            {/* The condition isForSale is because there is no need to display login
          as the default behavior of clicking the purchase button or sell button
          will prompt the user to login*/}
            {!user && !nft?.state?.isForSale && (
              <LoginButtonFlowConnector setModalIsVisible={setModalVisible} />
            )}
          </>
        )}

        {renderClaimStepList && (
          <ClaimStepList
            wallet={wallet}
            renderClaimStepList={renderClaimStepList}
            stepInitializeWallet={stepInitializeWallet}
          >
            {displayChangeWallet && (
              <>
                <ChangeWalletButtonsFlowConnector
                  currentOwnerAddress={nft?.currentAddress}
                  displayChangeWalletButton={displayChangeWalletButton}
                  displayConnectWallet={displayConnectWallet}
                />
              </>
            )}

            {!stepInitializeWallet && !displayChangeWallet && (
              <>
                <ClaimButtonFlowConnector
                  itemFID={itemFID}
                  contractAddress={contractAddress}
                  contractName={contractName}
                  setModalVisible={() => null}
                  claim={claim}
                  noModalClaim={true}
                  step="2"
                />
              </>
            )}
            {nft?.state &&
              nft.state.isOwner &&
              wallet &&
              nft.currentAddress !== wallet.address &&
              isNftClaimed && (
                <p className={"mt-2"}>
                  {`${strings.ownNftInAnotherWalletMsg} ${nft?.state?.ownedByFlowAddress}`}
                </p>
              )}
            {!isNftClaimed && stepInitializeWallet && (
              <>
                <ClaimButtonFlowConnector
                  itemFID={itemFID}
                  contractAddress={contractAddress}
                  contractName={contractName}
                  setModalVisible={setModalVisible}
                  claim={(walletAddress: string, nftID: number) =>
                    claim(walletAddress, nftID)
                  }
                />
              </>
            )}
          </ClaimStepList>
        )}
        {nft && nft.state?.isOwner && isNftClaimed && hasMarketplace && <hr />}
        {nft && nft.state?.isOwner && isNftClaimed && (
          <TokenInWalletSection
            contractAddressLink={contractAddressLink}
            blockChainName={Blockchain.BLOCKCHAIN_NAME}
            transactionLink={flowTransactionLink}
            isLoading={isLatestTransactionLoading}
            isError={isLatestTransactionError}
          />
        )}
      </NftShowUI>

      {nft && (
        <>
          <ActionModals
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            nft={nft}
            queryResults={queryResults}
          />
        </>
      )}
    </>
  );
}
