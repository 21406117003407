import {
  AnimatedNFT,
  Button,
  Chip,
  Modal,
  RoundImgCard,
  Checkbox,
} from "components";
import NewLineText from "components/AppComponent/NewLineText";
import Page from "components/Widgets/Page";
import { Config, getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "pages/Editions/show/ui/styles.module.scss";
import { useState } from "react";
import { AlertCircle, Maximize, Minimize } from "react-feather";
import {
  RedemptionCodeData,
  RedemptionCodeEdition,
  UserRedemptionStatusData,
} from "types";
import { LoginModal } from "../../../components/AppComponent";
import SmallNFTCard from "../../../components/AppComponent/SmallNFTCard";
import { useAppNavigation } from "../../../hooks/useNavigation";
import { getCurrentPosition } from "utils/geofencing";
import { updateBodyOverflow } from "utils/utils";

export type EditionMerchant = {
  name: string;
  image: string;
};

type Props = {
  edition: RedemptionCodeEdition;
  nft?:
    | UserRedemptionStatusData["mintedNft"]
    | UserRedemptionStatusData["ethNft"]
    | null;
  merchant: EditionMerchant | null;
  isLoggedIn: boolean;
  userLimitReached: boolean;
  globalLimitReached: boolean;
  codeLimitReached: boolean;
  submitRedemptionRequest?: (
    position?: GeolocationPosition,
    redemptionMrktOptedIn?: boolean
  ) => Promise<unknown>;
  editionsLeft: number | null;
  maxRedemptions?: number | null;
  editionsRedeemed: number | null;
  error: boolean;
  geoDenied: boolean;
  isRedemptionDone: boolean;
  isLoadingModalVisible: boolean;
  redemptionCodeData: RedemptionCodeData;
};

export function RedemptionNftUI({
  nft,
  edition,
  merchant,
  isLoggedIn,
  userLimitReached,
  globalLimitReached,
  codeLimitReached,
  submitRedemptionRequest,
  isLoadingModalVisible,
  editionsLeft,
  maxRedemptions,
  editionsRedeemed,
  error,
  geoDenied,
  isRedemptionDone,
  redemptionCodeData,
}: Props) {
  const detailsPageStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );
  const redeemModalStrings = getLocalizedStrings(
    "Modals",
    "Redeem",
    useLanguage()
  );
  const [isNftFullscreen, setIsNftFullscreen] = useState<boolean>(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isGeoLoading, setIsGeoLoading] = useState<boolean>(false);
  const [geoError, setGeoError] = useState<GeolocationPositionError | null>(
    null
  );
  const [isOptedInToMarketing, setIsOptedInToMarketing] =
    useState<boolean>(false);

  const nav = useAppNavigation();
  const actionContainerStyles = `${styles.action_container} ${styles.redeemButtonContainer}`;

  const isGeofenced = redemptionCodeData.redemptionCampaign.isGeofenced;
  const clientRedemptionMrktOptin = Config.Client.REDEMPTION_MARKETING_OPT_IN;

  //FYI videoURL is not necessarily a video, it can also be an image. Once the property name is changed to mediaURL on the backend, it will be updated here as well.
  const src = edition?.videoURL;
  let redemptionTitle = edition.name;
  if (edition.name === "LVII") {
    redemptionTitle += " Playoff Pick 'Em";
  }

  const itemID = (nft as any)?.itemFID ?? (nft as any)?.itemEID ?? null;
  const itemUuid = (nft as any)?.uuid ?? (nft as any)?.uuid ?? null;

  const smartContractAddress =
    edition.smart_contract?.address ?? edition.contractAddress;

  const renderEditionsLeftOnTotal = () => {
    if (maxRedemptions) {
      return (
        <>
          {editionsLeft} / {maxRedemptions}
        </>
      );
    } else {
      return <>{editionsLeft}</>;
    }
  };

  const geofenceOnSuccess = (data: GeolocationPosition) => {
    setIsGeoLoading(false);
    !clientRedemptionMrktOptin
      ? submitRedemptionRequest?.(data)
      : submitRedemptionRequest?.(data, isOptedInToMarketing);
  };

  const geofenceOnError = (error: GeolocationPositionError) => {
    setIsGeoLoading(false);
    setGeoError(error);
  };

  const handleRedeem = () => {
    if (isLoggedIn) {
      if (isGeofenced) {
        setIsGeoLoading(true);
        getCurrentPosition(geofenceOnSuccess, geofenceOnError);
      } else if (clientRedemptionMrktOptin && !isGeofenced) {
        submitRedemptionRequest?.(undefined, isOptedInToMarketing);
      } else {
        submitRedemptionRequest?.();
      }
    } else {
      setIsLoginModalVisible(true);
    }
  };

  return (
    <Page className={styles.editionPage}>
      <section className={styles.section_img}>
        <div className={styles.img_container}>
          {src && (
            <AnimatedNFT
              src={edition.videoURL || edition.thumbnail || ""}
              thumbnail={edition.thumbnail || undefined}
              name={edition.name || undefined}
              fileType={
                edition.videoURL
                  ? "Video"
                  : edition.thumbnail
                  ? "Image"
                  : undefined
              }
              icon={<Maximize />}
              onIconClick={() => setIsNftFullscreen(!isNftFullscreen)}
            />
          )}
        </div>
      </section>
      <section className={styles.section_info}>
        <div className={styles.info_container}>
          <h1 className={styles.redemption_title}>{redemptionTitle}</h1>
          <div className={styles.info_chips}>
            {Config.Client.REDEMPTION_SHOW_REMAINING && (
              <Chip type="secondary">
                {renderEditionsLeftOnTotal()}
                {detailsPageStrings.editionsRemaining}
              </Chip>
            )}
            {Config.Client.REDEMPTION_SHOW_REDEEMED && (
              <Chip type="secondary">
                {editionsRedeemed}
                {detailsPageStrings.editionsRedeemed}
              </Chip>
            )}
            {isGeofenced && (
              <Chip type="secondary">{detailsPageStrings.geofenced}</Chip>
            )}
          </div>
          {clientRedemptionMrktOptin && (
            <div className={styles.redeemOptinWrapper}>
              <Checkbox
                onChange={() => setIsOptedInToMarketing(!isOptedInToMarketing)}
              />
              <span>{detailsPageStrings.redemptionMrktOptinLabel}</span>
            </div>
          )}
          <div
            className={actionContainerStyles}
            id={styles.redeemButtonWrapper}
          >
            <Button
              disabled={
                userLimitReached ||
                globalLimitReached ||
                codeLimitReached ||
                error
              }
              type="primary"
              onClick={() => handleRedeem()}
            >
              {detailsPageStrings.redeemNFT}
            </Button>

            {isGeofenced && (
              <p className="mt-3">{detailsPageStrings.geofencedExplanation}</p>
            )}

            {userLimitReached && (
              <div className={`${styles.errorContainer} d-flex align-center`}>
                <AlertCircle className={"mr-2"} />
                <p className="my-3">
                  {detailsPageStrings.redemptionLimitReached}
                </p>
              </div>
            )}
            {(globalLimitReached || codeLimitReached) && (
              <div className={`${styles.errorContainer} d-flex align-center`}>
                <AlertCircle className={"mr-2"} />
                <p className="my-3">{detailsPageStrings.globalLimitReached}</p>
              </div>
            )}
            {error && !geoDenied && (
              <div className={`${styles.errorContainer} d-flex align-center`}>
                <AlertCircle className={"mr-2"} />
                <p className="my-3">{detailsPageStrings.unableToRedeem}</p>
              </div>
            )}
            {geoDenied && (
              <div className={`${styles.errorContainer} d-flex align-center`}>
                <AlertCircle size={75} className={"mr-3"} />
                <p className="my-3">{detailsPageStrings.geofencedDenied}</p>
              </div>
            )}
          </div>
          <NewLineText
            className={styles.edition_description}
            text={edition.description}
          />
          {detailsPageStrings.psNote && (
            <p className="my-3">{detailsPageStrings.psNote}</p>
          )}
          <hr className={styles.redemptionHr} />
          {merchant && (
            <RoundImgCard
              src={merchant.image}
              label={detailsPageStrings.creator}
              name={merchant.name}
              hasBorder={true}
              className={styles.merchant_card}
            />
          )}
        </div>
      </section>
      <Modal
        isVisible={isNftFullscreen}
        onClose={() => setIsNftFullscreen(false)}
        fullscreen
      >
        <AnimatedNFT
          src={edition.videoURL || edition.thumbnail || ""}
          thumbnail={edition.thumbnail || undefined}
          name={edition.name || undefined}
          fileType={
            edition.videoURL ? "Video" : edition.thumbnail ? "Image" : undefined
          }
          icon={<Minimize />}
          onIconClick={() => setIsNftFullscreen(!isNftFullscreen)}
        />
      </Modal>
      {isLoginModalVisible && (
        <LoginModal
          onCancel={() => setIsLoginModalVisible(false)}
          onComplete={() => {
            handleRedeem(), setIsLoginModalVisible(false);
          }}
        />
      )}
      {isLoadingModalVisible && !nft && (
        <Modal isVisible hideCloseButton clickOutsideToClose={false} btnLoading>
          <h2>{redeemModalStrings.loadingTitle}</h2>
          <p>
            {redeemModalStrings.loadingSubtitle}
            <br />
            {redeemModalStrings.pleaseWait}
          </p>
          <div className={styles.smallNftCard}>
            <SmallNFTCard redemptionCodeData={redemptionCodeData} loading />
          </div>
        </Modal>
      )}
      {isRedemptionDone && nft && (
        <Modal
          isVisible
          onActionClick={() =>
            nav.goToFlowNftPage({
              itemFID: itemID,
              smartContractName: edition.smart_contract?.name ?? null,
              smartContractAddress: smartContractAddress,
              uuid: itemUuid,
            })
          }
          onClose={() =>
            nav.goToFlowNftPage({
              itemFID: itemID,
              smartContractName: edition.smart_contract?.name ?? null,
              smartContractAddress: smartContractAddress,
              uuid: itemUuid,
            })
          }
          customStrings={{
            actionBtn: redeemModalStrings.viewCollectible,
          }}
          hideCloseButton
          clickOutsideToClose={false}
        >
          <h2>{redeemModalStrings.successfulTitle}</h2>
          <p>
            {redeemModalStrings.successfulSubtitle}
            <br />
            {redeemModalStrings.itemNowInWallet}
          </p>

          <div className={styles.smallNftCard}>
            <SmallNFTCard nft={nft} success />
          </div>
        </Modal>
      )}

      <Modal
        isVisible={isGeoLoading}
        onClose={() => {
          setIsGeoLoading(false);
          updateBodyOverflow(false);
        }}
        clickOutsideToClose={false}
        hideCloseButton
      >
        <h2>{detailsPageStrings.geofencedLoadingTitle}</h2>
        <p>{detailsPageStrings.geofencedExplanation}</p>
      </Modal>

      <Modal
        isVisible={!!geoError}
        onClose={() => {
          setGeoError(null);
          updateBodyOverflow(false);
        }}
      >
        <h2>{detailsPageStrings.geofencedErrorTitle}</h2>
        <p>
          {detailsPageStrings.geofencedErrorMessage}
          <br />
          <i>{geoError?.message}</i>
        </p>
        <p>{detailsPageStrings.geofencedExplanation}</p>
      </Modal>
    </Page>
  );
}
