import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../styles.module.scss";
import CollectiblesSection from "components/Widgets/Collectibles/CollectiblesSection";
import Collectible from "components/Widgets/Collectibles/Collectible";
import { Ruleset, Utility } from "types";
import { GATED_VIDEO_ROUTE, UTILITY_ROUTES } from "config/routes";
import { useNavigate } from "react-router-dom";
import { Lock } from "react-feather";

type Props = {
  videos: Utility.Video[];
  rulesets: Ruleset.Validation[] | undefined;
};

export default function Videos({ videos, rulesets }: Props) {
  const strings = getLocalizedStrings("Pages", "Utility", useLanguage());
  const navigate = useNavigate();

  return (
    <section className={styles.sectionContainer}>
      <CollectiblesSection
        title={strings.videos.title}
        description={strings.videos.description}
        displayShowAll={true}
        onShowAll={() => navigate(UTILITY_ROUTES.VIDEOS)}
      >
        {videos.map((video, i) => {
          const ruleset =
            rulesets?.filter((rs) => rs.id === video.rulesetId)[0] ?? null;
          const isAvailable = ruleset ? ruleset.ruleSetResult.isValid : false;

          return (
            <Collectible
              key={i}
              title={video.name ?? ""}
              description={video.description ?? ""}
              imageSrc={video.thumbnail ?? ""}
              onClick={() =>
                navigate(GATED_VIDEO_ROUTE.replace(":id", video.id.toString()))
              }
              label={
                !isAvailable ? (
                  <>
                    <Lock size={10} /> {strings.locked}
                  </>
                ) : undefined
              }
            />
          );
        })}
      </CollectiblesSection>
    </section>
  );
}
