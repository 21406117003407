import { Chip } from "components";
import { Blockchain, getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FC, FunctionComponent, ReactNode } from "react";
import { Wallet as EthWallet } from "types/app-model/eth";
import { Wallet as FlowWallet } from "types/app-model/flow";
import styles from "../ui/style.module.scss";

type Text = "pill_step" | "wallet_status" | "educational";
type Props = {
  wallet: FlowWallet | EthWallet | null;
  renderClaimStepList?: boolean | null;
  children: ReactNode;
  stepInitializeWallet?: boolean | undefined;
};

const ClaimStepListUI: FunctionComponent<Props> = ({
  wallet,
  children,
  stepInitializeWallet,
}) => {
  const strings = getLocalizedStrings("Pages", "Details", useLanguage());

  const renderText = (textToDisplay: Text) => {
    switch (Blockchain.BLOCKCHAIN_NAME) {
      case "flow":
        return handleFlowTextTextDisplay(textToDisplay);
      case "polygon":
        return handleEthTextDisplay(textToDisplay);
    }
  };

  const handleFlowTextTextDisplay = (textToDisplay: Text) => {
    if (!wallet) {
      switch (textToDisplay) {
        case "pill_step":
          return strings.flowClaimSteps.stepOne;
        case "wallet_status":
          return strings.walletNotConnected;
        case "educational":
          return strings.stepOneEducationalText;
      }
    } else if (!stepInitializeWallet) {
      switch (textToDisplay) {
        case "pill_step":
          return strings.flowClaimSteps.stepTwo;
        case "wallet_status":
          return strings.walletNotInitialized;
        case "educational":
          return strings.stepTwoEducationalText;
      }
    } else {
      switch (textToDisplay) {
        case "pill_step":
          return strings.flowClaimSteps.stepThree;
        case "wallet_status":
          return strings.nftNotClaimed;
        case "educational":
          return strings.finalEducationalText;
      }
    }
  };

  const handleEthTextDisplay = (textToDisplay: Text) => {
    if (!wallet) {
      switch (textToDisplay) {
        case "pill_step":
          return strings.EthClaimSteps.stepOne;
        case "wallet_status":
          return strings.walletNotConnected;
        case "educational":
          return strings.stepOneEducationalText;
      }
    } else if (wallet) {
      switch (textToDisplay) {
        case "pill_step":
          return strings.EthClaimSteps.stepTwo;
        case "wallet_status":
          return strings.nftNotClaimed;
        case "educational":
          return strings.finalEducationalText;
      }
    }
  };

  return (
    <>
      <div className={styles.pills_container}>
        <Chip type="secondary">{renderText("pill_step")}</Chip>

        <Chip type={"custom"} className={`${styles.alertPill}`}>
          {renderText("wallet_status")}
        </Chip>
      </div>
      <div className={styles.stepList}>
        <p> {renderText("educational")}</p>
        {children}
      </div>
    </>
  );
};

export const ClaimStepList: FC<Props> = (props) => {
  return props.renderClaimStepList ? <ClaimStepListUI {...props} /> : <></>;
};
