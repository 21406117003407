import { SmartContract } from "types";
import { NftResultStruct } from "../struct";
/***
 * This script is to centralize the NFT Result from the blockchain query
 * If a tokenID is provided, the script will only get the info for that particular NFT
 * else it will take all the NFT in the address provided
 *
 * Addr: Address of the wallet
 * Market: Market Address to check for nft info related to marketplace
 * tokenID: Token id to provided only if we want the method to act like a GET
 * @param contract
 */
export const getNftsByContract = (contract: SmartContract): any => `
  // This script gets all NFTs of a user for a single market
  import ${contract.name} from ${contract.address}
  import MintStoreMarketFactory from 0xMintStoreMarketFactory
  import MetadataViews from 0xMetadataViews
  
 ${NftResultStruct(contract)}
  
  pub fun main(addr: Address, market: Address, tokenID: UInt64?): [NftResult] {
      let nfts: [NftResult] = []
  
      let maybeAccount = getAccount(addr)
          .getCapability(${contract.name}.CollectionPublicPath)
          .borrow<&{${contract.name}.${contract.name}CollectionPublic}>()
          
      let maybeMarket = getAccount(market)
        .getCapability(MintStoreMarketFactory.MarketPublicPath)
        .borrow<&{MintStoreMarketFactory.MarketPublic}>()
    
      var maybeMarketCollection: &AnyResource{MintStoreMarketFactory.SalePublic}? = nil
  
      if let account = maybeAccount {
       let nftIds: [UInt64] = tokenID != nil ? [tokenID! as! UInt64] : account.getIDs()
          for id in nftIds {
              
              let maybeItem = account.borrow${contract.name}(id: id)
  
              if let item = maybeItem {
  
                  let nft = item.resolveView(Type<MetadataViews.Display>())! as! MetadataViews.Display
                  let editions = item.resolveView(Type<MetadataViews.Editions>())! as! MetadataViews.Editions
                  let traits = item.resolveView(Type<MetadataViews.Traits>())! as! MetadataViews.Traits
                  var price: UFix64? = nil   
  
                  if let market = maybeMarket {
                     maybeMarketCollection = getAccount(addr)
                    .getCapability(market.SaleCollectionPublicPath)
                    .borrow<&{MintStoreMarketFactory.SalePublic}>()
                  }

                  if let marketCollection = maybeMarketCollection {
                    price = marketCollection.getPrice(tokenID: id) ??  nil
                  }
                  
                 let nftResult = NftResult(
                  id: id,                 
                  price: price,
                  merchantID: item.data.merchantID,
                  displayView: nft,
                  editionsView: editions,
                  traits: traits.traits
                 )

                  nfts.append(nftResult)
              }
          }
      }
  
      return nfts
  }
`;
