import styles from "../common.module.scss";
import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import { useLanguage } from "lib/contexts/LanguageContext";
import { PropsWithChildren } from "react";
import ClaimSuccessfulSubtitle from "./ClaimSuccessfulSubtitle";
import { Eth, Flow } from "types";
import Modal from "components/Widgets/Modal";
import { useNavigate } from "react-router-dom";
import { NFT_LIST_ROUTE } from "config/routes";
import { getLocalizedStrings } from "config";

interface NftPreview {
  id?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
}

export type ClaimModalProps = {
  onClose: () => void;
  nft: NftPreview | null;
  queryResult: {
    isClaimError: boolean;
    isClaimLoading: boolean;
  };
  shouldRedirect?: boolean;
  isMinting?: boolean;
};

export function ClaimModal({
  onClose,
  nft,
  queryResult,
  children,
  shouldRedirect = false,
}: PropsWithChildren<ClaimModalProps>) {
  const strings = getLocalizedStrings("Modals", "Claim", useLanguage());
  const navigate = useNavigate();

  const { isClaimError, isClaimLoading } = queryResult;

  if (isClaimLoading) {
    return (
      <Modal isVisible clickOutsideToClose={false} hideCloseButton>
        <h2>{strings.claimInProgressTitle}</h2>
        <p>
          {strings.claimInProgressSubtitle}
          <br />
          {strings.pleaseWait}
        </p>
        <div className={styles.smallNftCard}>
          <SmallNFTCard nft={nft} loading={isClaimLoading} />
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        isVisible
        onActionClick={() => navigate(NFT_LIST_ROUTE)}
        onClose={() => onClose()}
        btnDisabled={false}
        customStrings={{
          actionBtn: strings.openWallet,
        }}
      >
        <h2>
          {isClaimError ? strings.unsuccessfulTitle : strings.congratulations}
        </h2>
        {isClaimError ? (
          <p>{strings.unsuccessfulSubtitle}</p>
        ) : (
          <ClaimSuccessfulSubtitle shouldRedirect={shouldRedirect} />
        )}
        <div className={styles.smallNftCard}>
          <SmallNFTCard nft={nft} success={!isClaimError} />
        </div>
        {children}
      </Modal>
    );
  }
}
