import React, { FunctionComponent } from "react";
import styles from "../styles.module.scss";
import { Props } from "..";
import { AnimatedNFT } from "components";
import { Utility } from "types";
import ReactPlayer from "react-player";
import { Lock } from "react-feather";
import LockedAlert from "components/AppComponent/LockedAlert";

type UIProps = {
  video: Utility.Video;
} & Props;

const VideoUI: FunctionComponent<UIProps> = ({ video, ruleset, strings }) => {
  return (
    <>
      {!video?.isAvailable && (
        <LockedAlert
          message={
            ruleset?.description
              ? strings.noAccessNFT
              : strings.noRulesDescription
          }
        />
      )}
      <div className={styles.container}>
        <div className={styles.headingImage}>
          <div className={styles.background}>
            <img src={video.thumbnail} />
          </div>
          <div className={styles.content}>
            <h1>{video.name}</h1>
          </div>
        </div>
        <div className={styles.grid}>
          <div className={styles.gridItem}>
            <div className={styles.card}>
              {video.videos.map((vid, i) => (
                <React.Fragment key={i}>
                  {vid.url && (
                    <ReactPlayer
                      url={vid.url}
                      controls={true}
                      width="100%"
                      height="100%"
                    />
                  )}
                  {!vid.url && (
                    <AnimatedNFT
                      src={video?.thumbnail}
                      thumbnail={video?.thumbnail}
                      name={video?.name}
                      fileType={"Image"}
                      className={styles.imageLocked}
                    >
                      <div className={styles.imgScrim}></div>
                      <div className={styles.imgLabel}>
                        <Lock size={20} /> {strings.locked}
                      </div>
                    </AnimatedNFT>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className={styles.gridItem}>
            <div className={styles.card}>
              <h2>{strings.info}</h2>
              <hr />
              <p>{video.description ?? <i>{strings.noDescription}</i>}</p>
            </div>
            {!video.isAvailable && (
              <div className={styles.card}>
                <h2>{strings.rules}</h2>
                <hr />
                <p>
                  {ruleset?.description ? (
                    <i>{strings.noAccessNFT}</i>
                  ) : (
                    <i>{strings.noRulesDescription}</i>
                  )}
                </p>
              </div>
            )}
            {/* Ruleset Tree not ready yet - leave it hidden until then! */}
            {/* <div className={styles.requirements}>
              <hr />
              <p>{strings.rulesetTree}</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoUI;
