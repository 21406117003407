import LoginSrc from "../img/YB-NFT_Collage-thin.png";
import LogoSvg from "../img/BSC_Young_Boys_Logo.png";
const LogoPng = LogoSvg;

const HTML_TITLE = "BSC YB Wallet Viewer";
const TEAM_CITY = "";
const TEAM_NAME = "BSC YB";
const COPYRIGHT = "BSC YB";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

const CUSTOM_HEADER_LINKS = [
  {
    name: "Utility",
    link: "https://collectorboards.bscyb.ch/",
  },
];

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
  DISPLAY_SCARCITY: true,
  DISPLAY_BREADCRUMBS: true,
  CUSTOM_HEADER_LINKS,
};
