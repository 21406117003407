import LoginSrc from "../img/login-photo-white.png";
import Logo from "../img/logo.svg";
import { yearNow } from "utils/utils";

const HTML_TITLE = "UMG Wallet Viewer";
const TEAM_CITY = "Universal Music";
const TEAM_NAME = "Universal Music";
const COPYRIGHT_YEAR = "";
const COPYRIGHT = `Copyright ${yearNow} Universal Music Group N.V. All rights reserved.`;
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = Logo;
const LOGO_PNG_SRC = Logo;
const PRIVACY_POLICY_URL = "https://www.universal-music.co.jp/privacy-policy/";

const TOSLINKS: { label: string; href: string }[] = [
  { label: "UMJ:", href: "https://collection.umj.jp/terms-of-service" },
  { label: "Mint:", href: "https://www.mint.store/terms-and-conditions" },
  { label: "Blocto:", href: "https://blocto.io/terms" },
];

export default {
  HTML_TITLE,
  TEAM_CITY,
  COPYRIGHT_YEAR,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
  REDEMPTION_SHOW_REMAINING: false,
  REDEMPTION_SHOW_REDEEMED: false,
  REDEMPTION_CAMPAIGN_SHOW_REMAINING: false,
  REDEMPTION_CAMPAIGN_SHOW_REDEEMED: false,
  HIDE_TRANSFER_BUTTON: true,
  HAS_FUSD: false,
  MARKETING_OPT_IN: false,
  PRIVACY_POLICY_URL,
  REDEMPTION_MARKETING_OPT_IN: true,
  TOSLINKS,
};
